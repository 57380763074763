import React from 'react'
import "./services.css"
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>Videos</h5>
      <h2>Creative Act: My Way of Being</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Without Question</h3>
          </div>
          <div style={{position: 'relative', "padding-top": '56.25%'}}><iframe src="https://customer-8logx8fsrytf1wnr.cloudflarestream.com/59f332b0e16eef4e707ee5dc51defc86/iframe?muted=true&loop=true&autoplay=true&poster=https%3A%2F%2Fcustomer-8logx8fsrytf1wnr.cloudflarestream.com%2F59f332b0e16eef4e707ee5dc51defc86%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600" style={{ border: 'none', position: 'absolute', top: '0', left: '0', height: '100%', width: '100%' }} allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true">
            </iframe>
          </div>
        </article>
        {/*END OF UI/UX*/}

        <article className="service">
          <div className="service__head">
            <h3>The Trail We Blaze</h3>
          </div>


          <div style={{position: 'relative', "padding-top": '56.25%'}}><iframe src="https://customer-8logx8fsrytf1wnr.cloudflarestream.com/adaae802766ee80791bf2fa7694f47d3/iframe?muted=true&loop=true&autoplay=true&poster=https%3A%2F%2Fcustomer-8logx8fsrytf1wnr.cloudflarestream.com%2Fadaae802766ee80791bf2fa7694f47d3%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600" style={{ border: 'none', position: 'absolute', top: '0', left: '0', height: '100%', width: '100%' }} allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true">
            </iframe>
            </div>
        </article>
        {/*END OF UI/UX*/}

        <article className="service">
          <div className="service__head">
            <h3>Black Jack Concert</h3>
          </div>


          <div style={{position: 'relative', "padding-top": '56.25%'}}><iframe src="https://customer-8logx8fsrytf1wnr.cloudflarestream.com/8d56e55c37d41588a3be8a45fc4481d5/iframe?muted=true&loop=true&autoplay=true&poster=https%3A%2F%2Fcustomer-8logx8fsrytf1wnr.cloudflarestream.com%2F8d56e55c37d41588a3be8a45fc4481d5%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600" style={{ border: 'none', position: 'absolute', top: '0', left: '0', height: '100%', width: '100%' }} allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true">
            </iframe>
          </div>
        </article>
        {/*END OF UI/UX*/}

      </div>
    </section>
  )
}

export default Services

import React from 'react'
import "./testimonials.css"
import AVTR1 from '../../assets/avatar10.jpg'
import AVTR2 from '../../assets/avatar11.jpg'
import AVTR3 from '../../assets/avatar12.jpg'
import AVTR4 from '../../assets/avatar13.jpg'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'

const data = [
  {
    avatar: AVTR1,
    name: 'Christian Dior',
    review: '"BY BEING NATURAL AND SINCERE, ONE OFTEN CAN CREATE REVOLUTIONS WITHOUT HAVING SOUGHT THEM."'
  },
  {
    avatar: AVTR2,
    name: 'Calvin Klein',
    review: '"MY WARDROBE CONSISTS OF ANTIQUE CLOTHES, MANY OF MY DESIGNS, PLUS SHOES AND SHIRTS FROM BROOKS BROTHERS AND PAUL STUART."'
  },
  {
    avatar: AVTR3,
    name: 'Raf Simons',
    review: "I DON'T WANT TO SHOW CLOTHES, I WANT TO SHOW MY ATTITUDE, MY PAST, PRESENT AND FUTURE. I USE MEMORIES AND FUTURE VISIONS AND TRY TO PLACE THEM IN TODAY'S WORLD."
  },
  {
    avatar: AVTR4,
    name: 'Rick Owens',
    review: '"I’M NOT REALLY INTO CLOTHES. I WEAR ONE OUTFIT LIKE A UNIFORM. I HAVE FOR YEARS. BLACK SWEATPANTS, BLACK BAGGY SHORTS OVER THEM, A BLACK OR WHITE COTTON T-SHIRT AND A BLACK CASHMERE T-SHIRT OVER THAT. I COULDN’T IMAGINE HAVING TO CHANGE OUTFITS EVERY DAY. OR HAVING TO CHANGE FOR THE GYM. AND THIS OUTFIT TAKES ME TO THE GYM, WORK IN THE STUDIO AND TO DINNER WITH A MINK COAT OVER IT. CHANGING YOUR BODY IS SO MUCH MORE HARDCORE. THE EXTREME SENSATION OF WORKING OUT HAS REPLACED THE MOSH PITS OF MY EARLIER YEARS AND THE SEX CLUBS FOR YEARS AFTER THAT. IT’S A GREAT COMBO OF DISCIPLINE, JOYOUS RELEASE, MEDITATION AND VANITY. MUSIC NEVER SOUNDED AS GOOD AS IT DOES NOW, POUNDING THROUGH THOSE EARBUD HEADPHONES INTO THE PIT OF MY STOMACH AS I FEEL MY MUSCLES SWELL."'
  }
]

const testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Inspriational Quotes from</h5>
      <h2>Designers</h2>

      <Swiper className="container testimonials__container" // install Swiper modules
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}>
        {
          data.map(({avatar,name,review}, index) => {
            return(
            <SwiperSlide key={index} className="testimonial">
            <div className="client__avatar">
              <img src={avatar} alt="Avatar One"/>
            </div>
              <h5 className='client__name'>{name}</h5>
              <small className='client__review'>{review}</small>
          </SwiperSlide>
          )
          })
        }
        </Swiper>
        </section>
  )  
}        

export default testimonials
import React from 'react'
import "./about.css"
import ME from '../../assets/avatar3.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const about = () => {
  return (
    <section id='about'>
      <h5> Get to Know Me!</h5>
      <h2>About</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>4+ Years of Working</small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>4+ Years of Working</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>8 Projects</small>
            </article>
          </div>
          <p>
            This is a beautiful page about my creative outlets. You will be able to see how I express myself and see the world through my lense.
          </p>
            <a href="#contact" className='btn btn-primary'>Let's Chat!</a>
        </div>

      </div>
    </section>
  )
}

export default about
import React from 'react'
import "./portfolio.css"
import IMG1 from '../../assets/avatar4.jpg'
import IMG2 from '../../assets/avatar5.jpg'
import IMG3 from '../../assets/avatar6.jpg'
import IMG4 from '../../assets/avatar7.jpg'
import IMG5 from '../../assets/avatar8.jpg'
import IMG6 from '../../assets/avatar9.jpg'

const data= [
  {
    id: 1,
    image: IMG1,
    title: 'Raf Simons SS03 Consumed',
    piece: 'https://www.grailed.com/listings/52911289-raf-simons-raf-simons-redux-consumed-bondage-pants?g_aidx=Listing_production&g_aqid=6743eab7a39f319ce547bfaeffe0abeb',
    show: 'https://www.youtube.com/watch?v=oXsQ4NMQ6B8'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Cactus Jack X HotWheels',
    piece: 'https://stockx.com/travis-scott-jackboys-racing-t-shirt-tie-dye',
    show: 'https://www.youtube.com/watch?v=RIuk23XHYj0'
  },
  {
    id: 3,
    image: IMG3,
    title: 'Raf Simons AW03 Closer',
    piece: 'https://www.grailed.com/listings/22060003-raf-simons-aw2003-closer-fishtail-parka-jacket-size-46?g_aidx=Listing_production&g_aqid=7012209526e4344d46c2ea3b63932a17',
    show: 'https://www.youtube.com/watch?v=6hVu-kYvlgI'
  },
  {
    id: 4,
    image: IMG4,
    title: 'Uniqlo X Spy X Family',
    piece: 'https://uniqlo.com/us/en/special-feature/spy-x-family',
    show: 'https://www.youtube.com/watch?v=jXtExN4ln3U'
  },
  {
    id: 5,
    image: IMG5,
    title: 'YZY GAP x Helmut Lang x Revenge Storm',
    piece: 'https://stockx.com/yeezy-x-gap-hoodie-purple',
    show: 'https://www.youtube.com/watch?v=k6ThNg6dszo'
  },
  {
    id: 6,
    image: IMG6,
    title: 'Fear of God Seventh Collection',
    piece: 'https://clothbase.com/items/c58710ca_fear-of-god-green-military-cargo-pants_fear-of-god',
    show: 'https://www.youtube.com/watch?v=9-V8UHl1cOs'
  },
]

const portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Taste in Clothing</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, piece,show}) => {
            return (
          <article key={id} className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={image} alt={title} />
          </div>
          <h3>{title}</h3>
          <div className="portfolio__item-cta">
            <a href={piece} className='btn'>Piece Link</a>
            <a href={show} className='btn btn-primary' target='_blank'>Show</a>
          </div>
        </article>)
          })
        }
      </div>
    </section>
  )
}

export default portfolio